import firebase from "gatsby-plugin-firebase";
import { useEffect } from "react";
import { getCookieConsentValue } from "react-cookie-consent";

const useFirebaseAnalytics = (event = "") => {
  useEffect(() => {
    const enabled = getCookieConsentValue("coffeeBooksAndCake") === "true";

    if (enabled) {
      if (!firebase || process.env.NODE_ENV !== "production") {
        // eslint-disable-next-line no-console
        console.log(`Firebase Analytics${enabled ? " enabled" : ""}: ${event}`);
        return;
      }
      firebase.analytics().setAnalyticsCollectionEnabled(true);
      if (event) {
        firebase.analytics().logEvent(event);
        return;
      }
      firebase.analytics();
    }
    if (!enabled) {
      firebase.analytics().setAnalyticsCollectionEnabled(false);
    }
  }, [event]);
};

export default useFirebaseAnalytics;
