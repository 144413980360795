import React from "react";
import styled from "styled-components";
import { media } from "../styled";
import BlogPostPreview from "../BlogPostPreview";

const CuratedLinks = ({ links }) => (
  <Grid>
    {links &&
      links.map(({ reference }) => (
        <li key={reference.id}>
          <BlogPostPreview
            isCuratedLinkItem
            publishedAt={reference.publishedAt}
            slug={reference.slug}
            mainImage={reference.mainImage}
            title={reference.title}
            rawExcerpt={reference._rawExcerpt}
          />
        </li>
      ))}
  </Grid>
);

const Grid = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2em;

  ${media.minMedium} {
    grid-template-columns: 1fr 1fr;
  }

  ${media.minLarge} {
    grid-template-columns: 1fr 1fr;
  }
`;

export default CuratedLinks;
