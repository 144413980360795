import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import { colour, Base } from "../styled";
import { kebabCase } from "../../helpers";

const AuthorList = ({ items, title }) => {
  return (
    <StyledAuthorList>
      <Headline>{title}</Headline>
      <StyledList>
        {items.map(({ author, _key }) => {
          const authorName = author && author.name;
          return (
            <ListItem key={_key}>
              <Link to={`/authors/${kebabCase(authorName)}`}>{authorName}</Link>
            </ListItem>
          );
        })}
      </StyledList>
    </StyledAuthorList>
  );
};

const StyledAuthorList = styled.div`
  margin: 2rem 0 2rem;
  border-top: 1px solid ${colour.veryLightGray};
`;

const Headline = styled(Base)`
  font-weight: 600;
  margin: 0.5rem 0 0;
`;

const StyledList = styled.ul`
  list-style: none;
  padding: 0.5rem 0;
`;

const ListItem = styled.li`
  margin: 0 0 1.5rem 0;
  a {
    padding: 0.5rem 0.5rem;

    text-decoration: none;
    border: 1px solid;
    border-radius: 8px;
    color: ${(props) => props.theme.accentColor};

    @media (hover: hover) {
      &:hover {
        color: inherit;
      }
    }
  }
`;

export default AuthorList;
