import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { media } from "../styled";
import BlogPostPreview from "../BlogPostPreview";
import { Container, Icon } from "../elements";
import { getBlogUrl } from "../../helpers";

const NextPrevItems = ({ nextItem, previousItem }) => (
  <Container>
    <Content>
      {nextItem && (
        <div>
          <BlogPostPreview
            publishedAt={nextItem.publishedAt}
            slug={nextItem.slug}
            mainImage={nextItem.mainImage}
            title={nextItem.title}
            rawExcerpt={nextItem._rawExcerpt}
            tags={nextItem.tags}
          />
          <StyledLink to={getBlogUrl(nextItem.slug.current)}>
            <Title>
              Next
              <IconWrapper>
                <Icon symbol="left" />
              </IconWrapper>
            </Title>
          </StyledLink>
        </div>
      )}
      {previousItem && (
        <div>
          <BlogPostPreview
            publishedAt={previousItem.publishedAt}
            slug={previousItem.slug}
            mainImage={previousItem.mainImage}
            title={previousItem.title}
            rawExcerpt={previousItem._rawExcerpt}
            tags={previousItem.tags}
          />
          <StyledLink to={getBlogUrl(previousItem.slug.current)}>
            <Title>
              Previous
              <IconWrapper>
                <Icon symbol="right" />
              </IconWrapper>
            </Title>
          </StyledLink>
        </div>
      )}
    </Content>
  </Container>
);

const Content = styled.div`
  margin: 1rem 0rem 3rem 0rem;
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1em;

  ${media.minSmall} {
    grid-template-columns: 1fr 1fr;
  }

  ${media.minMedium} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const Title = styled.h3`
  text-align: center;
`;

const IconWrapper = styled.div`
  padding-top: 1em;
`;

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.primaryColor};
  text-decoration: none;
`;

export default NextPrevItems;
