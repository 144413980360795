import React from "react";
import { format } from "date-fns";
import styled from "styled-components";
import { Link } from "gatsby";
import { buildImageObj, kebabCase, imageUrlFor } from "../../helpers";
import { PortableText, Container } from "../elements";
import AuthorList from "../AuthorList";

import { media, colour, ResponsiveTitle1, Small, Base } from "../styled";
import CuratedLinks from "../CuratedLinks";
import routes from "../../constants/routes";

const BlogPost = ({
  body,
  authors,
  genres,
  title,
  mainImage,
  publishedAt,
  curatedLinks,
  tags = [],
}) => {
  return (
    <Article>
      {mainImage && mainImage.asset && (
        <MainImage>
          <img
            src={imageUrlFor(buildImageObj(mainImage))
              .width(1200)
              .height(Math.floor((9 / 16) * 1200))
              .fit("crop")
              .auto("format")
              .url()}
            loading="lazy"
            alt={mainImage.alt}
          />
        </MainImage>
      )}
      <Container>
        <Grid>
          <MainContent>
            <ResponsiveTitle1>{title}</ResponsiveTitle1>
            {body && <PortableText blocks={body} />}
            {curatedLinks && curatedLinks.length > 0 && (
              <>
                <Divider />
                <CuratedLinks links={curatedLinks} />
                <Divider />
              </>
            )}
          </MainContent>
          <aside>
            {publishedAt && (
              <PublishedAt>
                {format(new Date(publishedAt), "MMMM do, yyyy")}
              </PublishedAt>
            )}
            {tags && tags.length > 0 ? (
              <StyledList>
                {tags.map(({ tag }) => (
                  <ListItem key={tag.title}>
                    <Link to={`${routes.tags}/${kebabCase(tag.title)}`}>
                      {tag.title}
                    </Link>
                  </ListItem>
                ))}
              </StyledList>
            ) : (
              <StyledList>
                <ListItem>
                  <Link to="/books">Book Reviews</Link>
                </ListItem>
              </StyledList>
            )}
            {authors && authors.length > 0 && (
              <AuthorList
                items={authors}
                title={`Author${authors.length > 1 ? "s" : ""}`}
              />
            )}
            {genres && genres.length > 0 && (
              <Genres>
                <GenresHeadline>
                  {`Genre${genres.length > 1 ? "s" : ""}`}
                </GenresHeadline>
                <StyledList>
                  {genres.map((category) => (
                    <ListItem key={category._id}>
                      <Link to={`/genres/${kebabCase(category.title)}`}>
                        {category.title}
                      </Link>
                    </ListItem>
                  ))}
                </StyledList>
              </Genres>
            )}
          </aside>
        </Grid>
      </Container>
    </Article>
  );
};

const Article = styled.article`
  color: ${(props) => props.theme.primaryColor};
  ${media.maxSmall} {
    margin-top: 1rem;
  }
`;

const MainImage = styled.div`
  display: block;
  position: relative;
  background: ${colour.veryLightGray};
  padding-bottom: calc(9 / 16 * 100%);

  img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 2em;

  ${media.minLarge} {
    grid-template-columns: 3fr 1fr;
  }
`;

const MainContent = styled.section`
  a {
    color: ${(props) => props.theme.accentColor};

    @media (hover: hover) {
      &:hover {
        color: inherit;
      }
    }
  }
`;

const PublishedAt = styled(Small)`
  margin: 1.5rem 0 3rem;
`;

const Genres = styled.div`
  border-top: 1px solid ${colour.veryLightGray};
`;

const StyledList = styled.ul`
  list-style: none;
  padding: 0.5rem 0;
`;

const ListItem = styled.li`
  margin: 0 0 1.5rem 0;
  a {
    padding: 0.5rem 0.5rem;

    text-decoration: none;
    border: 1px solid;
    border-radius: 8px;
    color: ${(props) => props.theme.accentColor};

    @media (hover: hover) {
      &:hover {
        color: inherit;
      }
    }
  }
`;

const GenresHeadline = styled(Base)`
  margin: 0.5rem 0 0;
`;

const Divider = styled.hr`
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
`;

export default BlogPost;
