import React from "react";
import LayoutContainer from "../containers";
import { Container, GraphQLError, SEO } from "../components/elements";
import BlogPost from "../components/BlogPost";
import useFirebaseAnalytics from "../hooks/useFirebaseAnalytics";
import { toPlainText } from "../helpers";
import NextPrevItems from "../components/NextPrevItems";

const GenericPost = ({ post, errors, tracking, nextItem, previousItem }) => {
  const {
    _rawBody: body,
    _rawExcerpt: excerpt,
    authors,
    genres,
    title,
    mainImage,
    tags = [],
    publishedAt,
    curatedLinks,
    keywords = [],
  } = post;

  const postKeywords = [
    title,
    ...(genres && genres.length > 0 ? genres.map((g) => g.title) : []),
    ...(tags && tags.length > 0
      ? tags.map((t) => t.tag.title)
      : ["Book Review"]),
    ...(authors && authors.length > 0 ? authors.map((a) => a.author.name) : []),
    ...keywords,
  ];

  useFirebaseAnalytics(tracking);
  return (
    <LayoutContainer>
      {errors && <SEO title="GraphQL Error" />}
      {post && (
        <SEO
          title={title || "Untitled"}
          description={toPlainText(excerpt)}
          image={mainImage}
          keywords={postKeywords}
        />
      )}

      {errors && (
        <Container>
          <GraphQLError errors={errors} />
        </Container>
      )}

      {post && (
        <BlogPost
          body={body}
          authors={authors}
          genres={genres}
          title={title}
          mainImage={mainImage}
          publishedAt={publishedAt}
          curatedLinks={curatedLinks}
          tags={tags}
        />
      )}
      <NextPrevItems nextItem={nextItem} previousItem={previousItem} />
    </LayoutContainer>
  );
};

export default GenericPost;
